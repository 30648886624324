$main_color: #3a31b2;
$hover_color: darken($main_color, 10%);
$text_color: #333;
$secondary_text_color: #666;

.blog-post-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
  text-align: left;

  .post-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;

    .back-button {
      background: $main_color;
      color: white;
      padding: 0.5rem 1rem;
      border-radius: 5px;
      text-decoration: none;
      font-size: 1rem;
      font-weight: bold;
      transition: background 0.2s;

      &:hover {
        background: $hover_color;
      }
    }
  }

  .loading-text {
    text-align: center;
    font-size: 1.2rem;
    color: $text_color;
  }

  .error-message {
    color: #d9534f;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
  }

  .post-content {
    text-align: left;

    .post-title {
      font-size: 2.5rem;
      font-weight: bold;
      color: $text_color;
      margin-bottom: 0.5rem;
    }

    .post-author,
    .post-date {
      font-size: 1rem;
      color: $secondary_text_color;
      margin-bottom: 0.5rem;
    }

    .post-thumbnail {
      width: 100%;
      max-height: 300px;
      object-fit: cover;
      border-radius: 8px;
      margin: 1rem 0;
    }

    .post-body {
      font-size: 1.2rem;
      line-height: 1.6;
      color: $text_color;

      p {
        margin-bottom: 1rem;
      }

      img {
        max-width: 100%;
        height: auto;
        border-radius: 5px;
      }
    }
  }
}

/* Responsive */
@media (max-width: 768px) {
  .blog-post-container {
    padding: 1rem;
  }

  .post-title {
    font-size: 2rem;
  }
}
